const OffersReducer = (state, action) => {
    switch (action.type) {
        case "OFFERS_START":
            return {
                offers: null,
                offersIsFetching: true,
                offersError: false
            };
        case "OFFERS_SUCCESS":
            return {
                offers: action.payload,
                offersIsFetching: false,
                offersError: false
            };
        case "OFFERS_FAIL":
            return {
                offers: { offers: [] },
                offersIsFetching: false,
                offersError: action.payload
            };
        case "OFFERS_CLEAR":
            return {
                offers: { offers: [] },
                offersIsFetching: false,
                offersError: false
            };

        default: return state;

    }
}
export default OffersReducer;