import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import "./unsubscribe.css";
import { offerwallapi } from "../../config/axiosconfig";

import "../../fonts/sfprotext.css";

export default function Unsubscribe() {

    const url = useLocation().pathname.split("/");
    const params = useParams();
    const [message, setMessage] = useState("");
    useEffect(() => {
        const unsub = async () => {
            try {
                const unsubscribeobj = await offerwallapi.put("/api/cards/emailunsubscription", {
                    externaluserid: params.euid,
                    distributorid: params.distributorid
                });
                console.log(unsubscribeobj)
                setMessage((unsubscribeobj) ? "Request submitted. Your request will take up to 5 Business days to be updated on our system."
                    : "Failed to Unsubscribe. Please try again later.")
            } catch (e) {
                setMessage("Something went wrong. Please try again later.")
            }
        }
        unsub();
    }, []);


    return <h3 className="text-center pt-5 mt-5 pb-5 mb-5"
    style={{fontFamily: 'SFProText'}}>{message}</h3>
}