import axios from "axios";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const refreshToken = async () => {
    try {
        const res = await offerwallauthapi.post("/clients/clientrefresh");
        return res.data;
    } catch (err) { }
};

const offerwallauthapi = axios.create({
    baseURL: process.env.REACT_APP_OFFERWALL_API,
    withCredentials: true,
});

const offerwallapi = axios.create({
    baseURL: process.env.REACT_APP_OFFERWALL_API,
    withCredentials: true,
});

offerwallapi.interceptors.request.use(
    async (config) => {
        // if (cookies.get("rtid")) {
        //     await refreshToken();
        // }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
offerwallapi.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (error.response.status === 403 || error.response.status === 401) {
        const loc = window.location.pathname;
        const tenanturl = loc.split("/")[1];
        // localStorage.removeItem("c");
        window.location.replace("/" + tenanturl + "/auth");

    } else {
        return Promise.reject(error);
    }
});

export { offerwallapi, offerwallauthapi }