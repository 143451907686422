import { createContext, useEffect, useReducer } from "react"
import LanguageReducer from "./LanguageReducer";

const LANGUAGE_INITIAL_STATE = {
    language: null,
    languageIsFetching: false,
    languageError: false
}

export const LanguageContext = createContext(LANGUAGE_INITIAL_STATE);

export const LanguageContextProvider = ({ children }) => {
    const [languagestate, languagedispatch] = useReducer(LanguageReducer, LANGUAGE_INITIAL_STATE);




    return <LanguageContext.Provider value={{
        language: languagestate.language,
        languageIsFetching: languagestate.languageIsFetching,
        languageError: languagestate.languageError,
        languagedispatch
    }}>{children}</LanguageContext.Provider>

}