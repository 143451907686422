import { createContext, useReducer } from "react"
import OffersReducer from "./OffersReducer";

const OFFERS_INITIAL_STATE = {
    offers: null, //JSON.parse(localStorage.getItem("owos")) || null,
    offersIsFetching: false,
    offersError: false
}

export const OffersContext = createContext(OFFERS_INITIAL_STATE);

export const OffersContextProvider = ({ children }) => {
    const [offersstate, offersdispatch] = useReducer(OffersReducer, OFFERS_INITIAL_STATE);

    // useEffect(() => {
    //     localStorage.setItem("owos", JSON.stringify(offersstate.offers));
    // }, [offersstate.offers]);

    return <OffersContext.Provider value={{
        offers: offersstate.offers,
        offersIsFetching: offersstate.offersIsFetching,
        offersError: offersstate.offersError,
        offersdispatch
    }}>{children}</OffersContext.Provider>

}