
import { useContext, useEffect, useState } from "react";
import { Badge, Button, Card, Col, FormLabel, Image, Ratio } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { TenantContext } from "../../context/tenant/TenantContext";
import "./offer.css";
import { ClientContext } from "../../context/client/ClientContext";
import { offerwallapi } from "../../config/axiosconfig";
import { LanguageContext } from "../../context/language/LanguageContext";
import { FormatDateShortMonth, minusDays, addDays } from "../../utils/dates";


export default function Offer({ offer, count, setLoader, setShowMessageModal, setMessage }) {
    const { tenant } = useContext(TenantContext);
    const { client } = useContext(ClientContext);
    const [activated, setActivated] = useState(false);
    const { language, languagedispatch } = useContext(LanguageContext);
    const params = useParams();
    const tenanturl = params.tenantpath;
    useEffect(() => {
        getOfferAttributes();
    }, []);
    const getOfferAttributes = async () => {
        if (client?.isenrolled) {
            try {
                const res = await offerwallapi.get("/api/offers/offergetattributes", {
                    params: {
                        externaluserid: client.externaluserid,
                        offerid: offer.id,
                        tenantid: tenant.tenantid,
                        clientid: client.clientid,
                        // cardId: card.cardId
                    }
                });

                setActivated(res.data.attributions[0]?.enroll)
            } catch (err) {

            }
        }
    }

    const actvateOffer = async () => {

        if (client?.isenrolled) {
            try {
                // console.log({
                //     externaluserid: client.externaluserid,
                //     offerid: offer.id,
                //     action: "ENROLL",
                //     tenantid: tenant.tenantid,
                //     clientid: client.clientid
                // })
                setLoader(true);
                const res = await offerwallapi.post("/api/offers/offerpostattribution", {
                    externaluserid: client.externaluserid,
                    offerid: offer.id,
                    action: "ENROLL",
                    tenantid: tenant.tenantid,
                    clientid: client.clientid
                });
                setMessage(language?.success_res.success_l8);
                setTimeout(() => { setActivated(true); setLoader(false) }, 2000);

            } catch (err) {
                setMessage(language?.error_res.error_l8);
                setTimeout(() => { setLoader(false) }, 2000);
            }
        } else {
            setShowMessageModal(true)
        }
    }
    return <div className="col-12 col-md-3">
        <Link to={`/${tenanturl}/offerdetail/${offer.id}`}
            className="offercardlink position-relative">
            <div className="offerGrid">
                <div className="offerGridImage position-relative">
                    <img src={offer.images[0]} />
                    <div className="position-absolute end-0 top-0 badge rounded-pill of-badge mt-2 me-2"
                    style={{backgroundColor: tenant?.tenantmasterconfig.theme.tag_color}}>
                        {
                            (offer.validTo && new Date() > minusDays(offer.validTo, 14)) ? "ENDING SOON" :
                                (new Date() < addDays(offer.published, 14)) ? "NEW"
                                    : ""
                        }
                    </div>
                </div>
                <div className="offerGridContent">
                    <div className="offerMerchant">
                        <div className="row">
                            <div className="merchantLogo d-inline w-auto">
                                <img src={offer.merchant.image} className="rounded-circle" />
                            </div>
                            <div className="col merchantName text-truncate text-black">{offer.merchant.name}</div>

                        </div>
                        <div className="offerTitle">
                            {offer.title}
                        </div>
                        <div className="offerOptionslist">
                            <ul>
                                {/* <li>{FormatDateShortMonth(offer.published)}</li>
                            <li>{FormatDateShortMonth(offer.validTo)}</li>*/}
                                {/* <li>{offer.totalRedemptions}</li> */}
                                {(offer.minimumSpend) ? <li>Minimum spend of  {offer.currency} ${offer.minimumSpend}</li> : ""}
                                {(offer.validTo) ? <li>Valid until {FormatDateShortMonth(offer.validTo)}</li> : ""}
                            </ul>
                        </div>

                    </div>
                </div>
            </div>
            <div className="onlineStore position-absolute">
                {(offer.salesChannel === "ALL") ? "Online & In-store" : (offer.salesChannel === "E-COMMERCE") ? "Online" : "In-Store"}
            </div>
        </Link>
    </div>

}