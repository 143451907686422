
import { Col, Container, Ratio, Row } from "react-bootstrap";
import "./skeleton.css";

export default function Skeleton({ type, count }) {

    const OfferSkeleton = () => (
        <div className="col-lg-3 col-sm-6">


            <div class="card" aria-hidden="true">
                <div className="skeleton-od-image"></div>
                <div class="card-body">
                    <h5 class="card-title placeholder-glow row">
                        <div class="skeleton-t-logo"></div>
                        <div class="col d-inline" style={{ marginTop: "32px"}}>
                            <span class="placeholder "></span>
                        </div>
                    </h5>
                    <p class="card-text placeholder-glow">
                        <span class="placeholder col-7 mt-3"></span>
                        <span class="placeholder col-4 mt-2"></span>
                    </p>
                </div>
            </div>
        </div>
    );

    const TenantSkeleton = () => (

        <div className="skeleton">
            <div style={{ height: "300px" }} className="skeleton-cbg">
                <div className="text-center text-white p-5 pt-0">
                    <div className="bg-dark" style={{ lineHeight: "300px" }}></div>
                </div>
            </div>
            <Container>
                <Row className="m-0">
                    <Col>
                        <div className="d-inline-block align-top me-4" style={{ marginTop: "-40px" }}>
                            <div className="skeleton-t-logo skeleton-cbg"></div>
                        </div>
                        <div className="d-inline-block mt-3 w-25">
                            <div className="d-block mt-2 p-3 w-100 skeleton-cbg"></div>
                            <div className="d-block mt-2 p-2 w-750 skeleton-cbg"></div>
                            <div className="d-block mt-2 p-2 w-50 skeleton-cbg"></div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );

    const OfferDetailSkeleton = () => (
        <div className="row mt-5">
            <div className="col-md-5 placehoder-glow">
                <div class="ratio ratio-1x1">
                    <div className="placeholder" style={{ backgroundColor: "#ccc" }}></div>
                </div>
            </div>
            <div className="col-md-7 mt-5 mt-md-0 ps-5">
                <div className="od-card">
                    <div className="card-body od-card-body placeholder-glow">
                        <div className="row">
                            <div className="w-auto">
                                <div
                                    // Replace with the path to your merchant logo
                                    className="od-merchant-logo placehoder placeholder-lg"
                                    style={{ backgroundColor: "#ccc" }}
                                ></div>
                            </div>
                            <div className="col section_title mt-5">
                                <div className="placeholder placeholder-lg w-50"></div>
                            </div>
                        </div>

                        <div className="od-offer-title mt-3 placeholder placeholder-lg w-75"></div>

                        <div className="mt-4">
                            <div className="placeholder w-25"></div>
                            <div className="placeholder w-50 mt-2"></div>
                        </div>

                        <div className="mt-5 mb-3">
                            <div className="w-25 placeholder"></div>
                        </div>
                        <div className="mt-5 od-tc">
                            <div className="row mt-5">
                                <div className="w-50 placeholder"></div>
                            </div>
                            <div className="row mt-3">
                                <div className="placeholder w-25 mt-3 d-block"></div>
                                <div className="placeholder w-100 mt-3 d-block"></div>
                                <div className="placeholder w-50 mt-3 d-block"></div>
                                <div className="placeholder w-75 mt-3 d-block"></div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    const CategorySkeleton = () => (
        <div id="catall" className="placeholder-glow d-inline-block category" >
            <div className="grid-icon placeholder m-auto mb-2" 
            style={{width:"50px", height:"50px", backgroundColor:"#ccc", borderRadius:"50%"}}></div>
            <div className="grid-label placeholder ps-5 pe-5"></div>
        </div>
    );

    if (type === "offer") return Array.from({ length: count }, (_, i) => <OfferSkeleton key={i} />);
    if (type === "tenant") return <TenantSkeleton />;
    if (type === "offerdetail") return <OfferDetailSkeleton />;
    if (type === "category") return Array.from({ length: count }, (_, i) => <CategorySkeleton key={i} />);

}