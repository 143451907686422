import "./signup.css";

import { Button, Col, FloatingLabel, Form, Row, Stack } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useContext, useEffect, useRef, useState } from "react";
import { TenantContext } from "../../context/tenant/TenantContext";
import { ClientContext } from "../../context/client/ClientContext";
import { offerwallapi } from "../../config/axiosconfig";
import OneTimePassword from "../../components/auth/OneTImePassword";
import { LanguageContext } from "../../context/language/LanguageContext";
import { CheckAuth } from "../../utils/auth";
import ReCAPTCHA from "react-google-recaptcha";

export default function SignUp() {

    const { tenant } = useContext(TenantContext);
    const { client, clientError } = useContext(ClientContext);
    const { language } = useContext(LanguageContext);

    // const txtclientname = useRef();
    // const txtclientpassword = useRef();
    const txtclientemail = useRef();
    const cboxtnc = useRef();
    const cboxpnc = useRef();
    const captchaRef = useRef(null);
    // const txtclientconfirmpassword = useRef();
    // const txtclientmobilenumber = useRef();
    // const txtclientvisacard = useRef();


    const [validated, setValidated] = useState(false);
    const [message, setMessage] = useState("");
    const [captchaMessage, setCaptchaMessage] = useState("");
    const [success, setSuccess] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        if (CheckAuth() && tenant) navigate(`/${tenant.tenanturlname}`);
        // if (tenant) txtclientemail.current.focus();
    }, [client, tenant]);// eslint-disable-line react-hooks/exhaustive-deps


    const ValidateInput = (event) => {

        const regexPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        const isValid = regexPattern.test(txtclientemail.current.value);

        const token = captchaRef.current.getValue();
        const isTnCChecked = cboxtnc.current.checked;
        const isPnCChecked = tenant.tenantuserpolicystatus ? cboxpnc.current.checked : true;

        (isTnCChecked && isValid && token !== "" && isPnCChecked) ? setIsDisabled(false) : setIsDisabled(true);

        // setIsDisabled((!isValid && token === ""));
    }
    // const ValidateCaptcha = (event) => {
    //     const token = captchaRef.current.getValue();
    //     if (token !== "") {
    //         setCaptchaMessage("");
    //     }
    // }

    const clientSignUp = (event) => {
        const token = captchaRef.current.getValue();

        setMessage("");
        setCaptchaMessage("");
        event.preventDefault();
        event.stopPropagation();

        const regexPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        const isValid = regexPattern.test(txtclientemail.current.value);
        const isTnCChecked = cboxtnc.current.checked;
        const isPnCChecked = tenant.tenantuserpolicystatus ? cboxpnc.current.checked : true;

        if (token === "") { setCaptchaMessage(language?.error_res.error_l7); }
        if (!isValid) { setMessage(language?.error_res.error_l1); }

        if (isTnCChecked && isValid && token !== "" && isPnCChecked) {


            const signUp = async () => {
                // clientdispatch({ type: "CLIENT_START" });

                //captchaRef.current.reset();
                let client = {
                    // clientname: txtclientname.current.value,
                    clientemail: txtclientemail.current.value,
                    // clientpassword: txtclientpassword.current.value,
                    // clientname : txtclientconfirmpassword.current.value,
                    // clientmobilenumber: txtclientmobilenumber.current.value,
                    // clientvisacard: txtclientvisacard.current.value,
                    clienttenantid: tenant.tenantid,
                    defaultlanguageid: language.languageid,
                    captchatoken: token
                }

                try {
                    const res = await offerwallapi.post("/api/clients/clientcreate", client);
                    // if (res.data.hasOwnProperty("message")) {
                    // setSuccess(true);
                    // captchaRef.current.reset();
                    if (res.data.code === "OWMS0001") {
                        setSuccess(true);
                    } else if ((res.data.code === "OWER0001")) {
                        // clientdispatch({ type: "CLIENT_FAIL", payload: true });
                        setMessage(language?.error_res.error_l5);
                    } else if ((res.data.code === "OWER0002")) {
                        // clientdispatch({ type: "CLIENT_FAIL", payload: true });
                        setMessage(language?.error_res.error_l8);
                    } else if ((res.data.code === "OWER0003")) {
                        // clientdispatch({ type: "CLIENT_FAIL", payload: true });
                        setMessage(language?.error_res.error_l16);
                    } else if ((res.data.code === "OWER0004")) {
                        // clientdispatch({ type: "CLIENT_FAIL", payload: true });
                        setMessage(language?.error_res.error_l8);
                    }
                    captchaRef.current.reset();
                    // else {
                    //     setSuccess(true);
                    // setMessage("Please check your emails for verification email");
                    // clientdispatch({ type: "CLIENT_SUCCESS", payload: res.data });
                    // navigate(`/${tenant.tenanturlname}/signup`);


                } catch (err) {
                    // alert("ERROR");
                    // lblerrormessage.current.value = `Your email address is invalid. Please contact ${tenant.tenantname}`;
                    // clientdispatch({ type: "CLIENT_FAIL", payload: true });

                    setMessage(`${language?.error_res.error_l8} ${tenant.tenantname}`);
                    captchaRef.current.reset();
                    // setSuccess(true);
                    // captchaRef.current.reset();

                }
            }
            signUp();

            // navigate("/" + tenant.tenanturlname);
        }
    };
    return <>

        {(tenant) ? <div>
            <Form noValidate validated={validated} onSubmit={clientSignUp}>
                <Stack className="col-md-4 mx-auto mt-5 pt-5 text-center">
                    <div className="ms-4 me-4">
                        <Link to={`/${tenant.tenanturlname}`} className="text-dark" style={{ fontSize: "30px", float: "left" }}>
                            <i className="bi bi-chevron-left"></i>
                        </Link>
                        <h3>{language?.signup_labels.signup_l1}</h3>
                    </div>
                    <p className="small">{language?.signup_labels.signup_l2}</p>
                    <p className="small">{language?.signup_labels.signup_l3}&nbsp;
                        <Link to={`/${tenant.tenanturlname}/signin`} variant="primary" className="ow-primary">{language?.signup_labels.signup_l4}</Link></p>
                </Stack>

                <Stack className="col-md-4 mx-auto text-center" style={{ display: (!success) ? "" : "none" }}>
                    <Form.Group controlId="validationCustom03" className="ms-4 me-4">
                        <Row>
                            <Col sm="12" md="12">
                                <FloatingLabel
                                    controlId="txtclientemail"
                                    label={language?.signup_labels.signup_l5}
                                    className="mb-3 mt-3 small">
                                    <Form.Control type="email" placeholder={language?.signup_labels.signup_l6} ref={txtclientemail}
                                        required
                                        onChange={ValidateInput}
                                    />
                                    <Form.Control.Feedback type="invalid" className="text-right">
                                        {language?.error_res.error_l1}
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                {(message !== "") ? <Form.Label className="text-danger">
                                    {message}
                                </Form.Label> : ""}
                            </Col>
                        </Row>
                    </Form.Group>

                </Stack>
                <Stack className="col-md-4 mx-auto ps-4 pe-4 " style={{ display: (!success) ? "" : "none" }}>
                    <Form.Group className="mb-1" controlId="formBasicCheckbox">
                        <p className="small">
                            <table>
                                <tr>
                                    <td className="align-top pe-1"><Form.Check ref={cboxtnc} type="checkbox" variant="primary" className="d-inline border-secondary" onChange={ValidateInput} /></td>
                                    <td>{language?.signup_labels.signup_l12}&nbsp;
                                        <Link to={`/${tenant.tenanturlname}/terms`} target="_blank" variant="primary" className="ow-primary">{language?.signup_labels.signup_l10}</Link></td>
                                </tr>
                            </table>
                        </p>
                    </Form.Group>
                </Stack>

                {(tenant.tenantuserpolicystatus) ?
                    <Stack className="col-md-4 mx-auto ps-4 pe-4 " style={{ display: (!success) ? "" : "none" }}>
                        <Form.Group className="mb-3" controlId="formBasicCheckbox">
                            <p className="small">
                                {(language?.signup_labels.signup_l13.includes("[PRIVACY_POLICY]")) ?
                                    <div>
                                        <table>
                                            <tr>
                                                <td className="align-top pe-1">
                                                    <Form.Check ref={cboxpnc} type="checkbox" variant="primary" className="d-inline border-secondary" onChange={ValidateInput} /> &nbsp;
                                                </td>
                                                <td>
                                                    {language?.signup_labels.signup_l13.split("[PRIVACY_POLICY]")[0]}
                                                    <Link to={`/${tenant.tenanturlname}/terms`} target="_blank" variant="primary" className="ow-primary">{language?.signup_labels.signup_l11}</Link>
                                                    {language?.signup_labels.signup_l13.split("[PRIVACY_POLICY]")[1]}
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                    :
                                    <div>
                                        <table>
                                            <tr>
                                                <td className="align-top pe-1">
                                                    <Form.Check ref={cboxpnc} type="checkbox" variant="primary" className="d-inline border-secondary" onChange={ValidateInput} />&nbsp;
                                                </td>
                                                <td>
                                                    {language?.signup_labels.signup_l13}&nbsp;
                                                    <Link to={`/${tenant.tenanturlname}/terms`} target="_blank" variant="primary" className="ow-primary">{language?.signup_labels.signup_l11}</Link>
                                                </td>
                                            </tr>
                                        </table>


                                    </div>
                                }
                            </p>
                        </Form.Group>
                    </Stack>
                    : ""
                }

                <Stack className="col-md-4 mx-auto text-center">
                    {(!success) ?
                        <div>
                            <div className="d-grid ms-4 me-4 ">
                                <ReCAPTCHA ref={captchaRef} sitekey="6LcUouMgAAAAAOSIM89UzdNdAMFKT8BlJqgOXzKG" //{process.env.REACT_APP_RECAPTCHA_KEY}
                                    style={{ margin: "auto" }} onChange={ValidateInput} />
                                {(captchaMessage !== "") ? <p className="small text-danger">{captchaMessage}</p> : ""}
                            </div>
                            <div className="d-grid mb-sm-3 mb-md-5 mt-3 ms-4 me-4">
                                <Button type="submit" variant="primary" className="fw-bold p-3 ow-btn-primary" disabled={isDisabled}>{language?.signup_labels.signup_b1}</Button>
                                {/* <Link to={`/${tenant.tenanturlname}`} className="btn btn-outline-secondary mt-2 p-3">Back</Link> */}
                            </div>
                        </div> :
                        <Form.Text className="small m-3 ms-4 me-4 ow-bg-primary-light p-5 fs-5" >
                            {language?.signup_labels.signup_l7}
                        </Form.Text>
                    }
                    <p className="small">
                        {language?.signup_labels.signup_l8} &nbsp;
                        <Link to={`/${tenant.tenanturlname}`} variant="primary" className="ow-primary">{language?.signup_labels.signup_l9}</Link>
                    </p>

                </Stack>
            </Form>
            {/* <OneTimePassword clientemail={txtclientemail.current.value} /> */}
        </div>
            : ""}


    </>
}
