const LanguageReducer = (state, action) => {
    switch (action.type) {
        case "LANGUAGE_START":
            return {
                language: null,
                languageIsFetching: true,
                languageError: false
            };
        case "LANGUAGE_SUCCESS":
            return {
                language: action.payload,
                languageIsFetching: false,
                languageError: false
            };
        case "LANGUAGE_FAIL":
            return {
                language: null,
                languageIsFetching: false,
                languageError: action.payload
            };
        default: return state;

    }
}
export default LanguageReducer;