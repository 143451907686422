import Cookies from "universal-cookie";



export const CheckAuth = () => {
    const cookies = new Cookies();
    const as = cookies.get("as");
    const rtid = cookies.get("rtid");

    if (as && rtid) { return true; }
    if (as && !rtid) { return true; }
    if (!as && !rtid) { return false; }
}

