import React, { useContext } from 'react';
import OfferItem from './OfferItemSection';
import { OffersContext } from '../../context/offers/OffersContext';
import Offer from './Offer';
import OfferScroll from './OfferScroll';
// import OfferItemSection from "../sectionlayouts/OfferItemSection";
import './recommendedoffers.css';

export default function RecommendedOffers({ category, offerId }) {
  const { offers } = useContext(OffersContext);
  let i = 0;
  let recOffers = [];
  return <div className="recoffercontainer" >
    {
      offers?.map((offer, index) => {
        console.log(category, offerId)
        if (category === offer?.merchant.category.name && offer?.id !== offerId) {
          if (i < 4) {
            i++;
            recOffers.push(offer.id);
            return <OfferScroll key={offer.id} offer={offer} isScroll={true} />
          }
        }
      })

    }
    {
      (i < 3) ?
        offers?.map((offer) => {


          if (i < 4 && offer.id !== offerId && recOffers.indexOf(offer.id) === -1) {
            i++;
            return <OfferScroll key={offer.id} offer={offer} isScroll={true} />
          }

        })
        : ""
    }


  </div>

};
