import { createContext, useReducer } from "react"
import TenantReducer from "./TenantReducer";

const TENANT_INITIAL_STATE = {
    tenant:  null, //JSON.parse(localStorage.getItem("t")) ||
    tenantIsFetching: false,
    tenantError: false
}

export const TenantContext = createContext(TENANT_INITIAL_STATE);

export const TenantContextProvider = ({ children }) => {
    const [tenantstate, tenantdispatch] = useReducer(TenantReducer, TENANT_INITIAL_STATE);

    // useEffect(() => {
    //     localStorage.setItem("t", JSON.stringify(tenantstate.tenant));
    // }, [tenantstate.tenant]);

    return <TenantContext.Provider value={{
        tenant: tenantstate.tenant,
        tenantIsFetching: tenantstate.tenantIsFetching,
        tenantError: tenantstate.tenantError,
        tenantdispatch
    }}>{children}</TenantContext.Provider>

}