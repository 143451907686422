import { createContext, useReducer } from "react"
import CardReducer from "./CardReducer";

const CARD_INITIAL_STATE = {
    card:  null, //JSON.parse(localStorage.getItem("t")) ||
    cardIsFetching: false,
    cardError: false
}

export const CardContext = createContext(CARD_INITIAL_STATE);

export const CardContextProvider = ({ children }) => {
    const [cardstate, carddispatch] = useReducer(CardReducer, CARD_INITIAL_STATE);

    // useEffect(() => {
    //     localStorage.setItem("t", JSON.stringify(cardstate.card));
    // }, [cardstate.card]);

    return <CardContext.Provider value={{
        card: cardstate.card,
        cardIsFetching: cardstate.cardIsFetching,
        cardError: cardstate.cardError,
        carddispatch
    }}>{children}</CardContext.Provider>

}