import { useContext, useEffect, useState } from "react"
import { OffersContext } from "../../context/offers/OffersContext"
import Offer from "../../components/offers/Offer";
import { Link, useParams } from "react-router-dom";


export default function OfferSearch() {
    const { offers } = useContext(OffersContext);
    const params = useParams();
    const searchText = params.searchtext;
    const tenanturl = params.tenantpath;
    const merchantName = params.merchantname;

    const [searchedOffers, setSearchedOffers] = useState();
    const [searching, setSearching] = useState(true);
    useEffect(() => {
        if (offers) {
            console.log("searchText", searchText)
            console.log("merchantid", merchantName)

            if (searchText) {

                setSearching(true)
                setSearchedOffers([])
                const offerlist = [];
                offers?.map((offer, index) => {
                    console.log(offer)
                    if (offer.title.toLowerCase().includes(searchText.toLowerCase()) ||
                        // offer.description.toLowerCase().includes(searchText.toLowerCase()) ||
                        offer.merchant.name.toLowerCase().includes(searchText.toLowerCase()))
                        offerlist.push(offer);

                })
                setTimeout(() => {
                    setSearchedOffers(offerlist)
                    setSearching(false)

                }, 2500)
            } else if (merchantName) {
                setSearchedOffers([])
                const offerlist = [];
                offers?.map((offer, index) => {
                    console.log(offer)
                    if (offer.merchant.name === merchantName)
                        offerlist.push(offer);
                })
                setSearchedOffers(offerlist)
                setSearching(false)

            } else {
                setSearchedOffers(offers)
                setSearching(false)

            }

        }
    }, [offers, searchText, merchantName])

    return <div className="container pt-5">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <i class="bi bi-house me-2"></i>
                    <Link to={`/${tenanturl}`} className="text-decoration-none text-black">Home</Link></li>
                <li className="breadcrumb-item">
                    <Link to={`/${tenanturl}/offers`} className="text-decoration-none text-black">Offers</Link>
                </li>
                <li class="breadcrumb-item active" aria-current="page" style={{ color: "#3BBD9C", width: "max-content" }}>
                    {
                        (searchText) ? "Search" : (merchantName) ? merchantName : "Offers"
                    }
                </li>
            </ol>
        </nav>
        <div className="row pb-15 wow fadeIn g-3" data-wow-duration="2s" data-wow-delay="0.5s">
            {
                (searchedOffers && searchedOffers.length > 0) ?
                    searchedOffers?.map((offer, index) => {
                        return <Offer offer={offer} key={index} />
                    })
                    : <div className="text-center m-5">
                        <h1 className="m-5 p-5">
                            {
                                (searching) ? "Loading..." : "No offers found."
                            }
                        </h1>
                    </div>
            }
        </div>
    </div>
}