const TenantReducer = (state, action) => {
    switch (action.type) {
        case "TENANT_START":
            return {
                tenant: null,
                tenantIsFetching: true,
                tenantError: false
            };
        case "TENANT_SUCCESS":
            return {
                tenant: action.payload,
                tenantIsFetching: false,
                tenantError: false
            };
        case "TENANT_FAIL":
            return {
                tenant: null,
                tenantIsFetching: false,
                tenantError: action.payload
            };
        default: return state;

    }
}
export default TenantReducer;