import React from "react";

const OfferItem = ({ offer }) => {
  return (
    <div className="col-lg-3 col-sm-6">
      <div className="offerGrid">
        <div className="offerGridImage">
          <img src={offer.img[0]} alt={offer.title} />
        </div>
        <div className="offerGridContent">
          <div className="offerMerchant">
            <div className="merchantLogo">
              <img src={offer.mImg} alt={offer.mName} />
            </div>
            <div className="merchantName">{offer.mName}</div>
            <div className="offerTitle">{offer.title}</div>
            <div className="offerOptionslist">
              <ul>
                {/* {offer.details.map((detail, index) => (
                  <li key={index}>{detail}</li>
                ))} */}
                <li>Minimum spend of US$100</li>
                <li>Valid until 31 Jan 2024</li>
              </ul>
            </div>
            <div className="onlineStore">
              Online & In-store
              {/* {offer.storeType} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OfferItem;
