import { useContext, useState } from "react";
import { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { offerwallapi } from "../../config/axiosconfig";
import { ClientContext } from "../../context/client/ClientContext";
import { LanguageContext } from "../../context/language/LanguageContext";
import { TenantContext } from "../../context/tenant/TenantContext";

export default function Verify() {


    const clientToken = useLocation().pathname.split("verify/")[1];


    const [message, setMessage] = useState("");
    const { tenant } = useContext(TenantContext);
    const navigate = useNavigate();
    const { language } = useContext(LanguageContext);

    const { client, clientError, clientdispatch } = useContext(ClientContext);

    useEffect(() => {

        const verify = async () => {

            try {
                clientdispatch({ type: "OFFERS_START" });

                const res = await offerwallapi.post("/api/clients/clientverification", {
                    clienttoken: clientToken,
                    clienttenantid: tenant.tenantid
                });

                if (res.status === 202 || res.status === 201) {
                    clientdispatch({ type: "CLIENT_SUCCESS", payload: res.data });
                    setMessage(language?.success_res.success_l6);
                    setTimeout(function () {

                        if (res.data.isenrolled) {
                            navigate("/" + tenant.tenanturlname);
                        } else {
                            navigate("/" + tenant.tenanturlname + "/profile");
                        }
                    }, 2500);
                } else {
                    clientdispatch({ type: "CLIENT_FAIL", payload: "ERROR" });
                    setMessage(language?.error_res.error_l4);
                    setTimeout(function () {
                        navigate("/" + tenant.tenanturlname);
                    }, 2500);
                }
            } catch (err) {
                clientdispatch({ type: "CLIENT_FAIL", payload: "ERROR" });
                setMessage(language?.error_res.error_l3);
                setTimeout(function () {
                    navigate("/" + tenant.tenanturlname);
                }, 2500);
            }

        }
        if (tenant && language) verify();
    }, [language, tenant])




    return <div className="ow-full-overlay">
        <div className="position-absolute ow-content-center text-center">
            <Spinner animation="border" backdrop="static" variant="light" />
            <h3 className="text-light">{message}</h3>
        </div>
    </div>
}