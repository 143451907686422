import { createContext, useReducer } from "react"
import CategoriesReducer from "./CategoriesReducer";

const CATEGORIES_INITIAL_STATE = {
    categories: null, //JSON.parse(localStorage.getItem("owos")) || null,
    categoriesIsFetching: false,
    categoriesError: false
}

export const CategoriesContext = createContext(CATEGORIES_INITIAL_STATE);

export const CategoriesContextProvider = ({ children }) => {
    const [categoriesstate, categoriesdispatch] = useReducer(CategoriesReducer, CATEGORIES_INITIAL_STATE);

    // useEffect(() => {
    //     localStorage.setItem("owos", JSON.stringify(categoriesstate.categories));
    // }, [categoriesstate.categories]);

    return <CategoriesContext.Provider value={{
        categories: categoriesstate.categories,
        categoriesIsFetching: categoriesstate.categoriesIsFetching,
        categoriesError: categoriesstate.categoriesError,
        categoriesdispatch
    }}>{children}</CategoriesContext.Provider>

}