import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { TenantContextProvider } from './context/tenant/TenantContext';
import { ClientContextProvider } from './context/client/ClientContext';
import { OffersContextProvider } from './context/offers/OffersContext';
import { LanguageContextProvider } from './context/language/LanguageContext';
import { CardContextProvider } from './context/card/CardContext';
import { CategoriesContextProvider } from './context/categories/CategoriesContext';

const container = document.getElementById('owroot');
const root = createRoot(container);
root.render(
<BrowserRouter>
    <React.StrictMode>
      <LanguageContextProvider>
        <TenantContextProvider>
          <ClientContextProvider>
            <CardContextProvider>
              <OffersContextProvider>
                <CategoriesContextProvider>
                  <App />
                </CategoriesContextProvider>
              </OffersContextProvider>
            </CardContextProvider>
          </ClientContextProvider>
        </TenantContextProvider>
      </LanguageContextProvider>
    </React.StrictMode>
  </BrowserRouter>
);
// ReactDOM.render(
//   <BrowserRouter>
//     <React.StrictMode>
//       <LanguageContextProvider>
//         <TenantContextProvider>
//           <ClientContextProvider>
//             <CardContextProvider>
//               <OffersContextProvider>
//                 <CategoriesContextProvider>
//                   <App />
//                 </CategoriesContextProvider>
//               </OffersContextProvider>
//             </CardContextProvider>
//           </ClientContextProvider>
//         </TenantContextProvider>
//       </LanguageContextProvider>
//     </React.StrictMode>
//   </BrowserRouter>,

//   document.getElementById("owroot")
// );


