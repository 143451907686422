const CardReducer = (state, action) => {
    switch (action.type) {
        case "CARD_START":
            return {
                card: null,
                cardIsFetching: true,
                cardError: false
            };
        case "CARD_SUCCESS":
            return {
                card: action.payload,
                cardIsFetching: false,
                cardError: false
            };
        case "CARD_FAIL":
            return {
                card: null,
                cardIsFetching: false,
                cardError: action.payload
            };
        default: return state;

    }
}
export default CardReducer;