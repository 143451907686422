import { useContext, useState } from "react";
import { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { offerwallapi } from "../../config/axiosconfig";
import { ClientContext } from "../../context/client/ClientContext";
import { LanguageContext } from "../../context/language/LanguageContext";
import { TenantContext } from "../../context/tenant/TenantContext";

export default function Session() {
    const { tenant } = useContext(TenantContext);
    // const { client, clientdispatch } = useContext(ClientContext);
    const navigate = useNavigate();
    const { language } = useContext(LanguageContext);
    useEffect(() => {
        if (tenant) {
            setTimeout(async () => {
                

                // await offerwallapi.post("/api/clients/clientsignout", {
                //     clientid: client.clientid,
                //     tenantid: tenant.tenantid
                // });
                // clientdispatch({ type: "CLIENT_SIGNOUT" });
                setTimeout(() => { navigate("/" + tenant.tenanturlname + "/signin"); }, 2000);

            }, 3000);
        }
    }, [tenant])
    return <div className="ow-full-overlay">
        <div className="position-absolute ow-content-center text-center">
            <Spinner animation="border" backdrop="static" variant="light" />
            <h3 className="text-light">{language?.static_labels.autosignedout}</h3>
        </div>
    </div>
}