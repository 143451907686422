import React, { useState } from "react";
import { offerwallapi } from "../../config/axiosconfig";
import {Form, Container, Row, Col, Button } from "react-bootstrap";
import "./aws.css";

const AwsRankingForm = () => {
  const [formData, setFormData] = useState({
    eventValue: "",
    itemId: "",
    externalUserId: "",
    eventType: "View",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
      try {
        let response = await offerwallapi.post("/api/offers/personalisationPutEventsAPI", {
          externalUserId: formData.externalUserId,
          itemId: formData.itemId,
          eventValue: formData.eventValue,
          eventType: formData.eventType
        });
      } catch (error) {
        console.error('error:: ', error);
      }
    alert("Form submitted!");
  };
  
  const countries = [
    "View",
    "Add-to-cart",
    "Purchase",
  ];

  return (
    <Container className="form-container">
      <h1 className="form-title">AWS Personalisation Events</h1>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <Form.Group className="mb-3" controlId="formEventValue">
              <Form.Label>Event Value</Form.Label>
              <Form.Control
                type="text"
                name="eventValue"
                value={formData.eventValue}
                onChange={handleInputChange}
                placeholder="eg:- 0.66"
                required={true}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" controlId="formItemId">
              <Form.Label>Item ID</Form.Label>
              <Form.Control
                type="text"
                name="itemId"
                value={formData.itemId}
                onChange={handleInputChange}
                placeholder="eg:- Offer Id = OID15"
                required={true}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className="mb-3" controlId="formExternalUserId">
              <Form.Label>External User ID</Form.Label>
              <Form.Control
                type="text"
                name="externalUserId"
                value={formData.externalUserId}
                onChange={handleInputChange}
                placeholder="externalUserId"
                required={true}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" controlId="formEventType">
              <Form.Label>Event Type</Form.Label>
              <Form.Select
                name="eventType"
                value={formData.eventType}
                onChange={handleInputChange}
              >
                {countries.map((eventType) => (
                  <option key={eventType} value={eventType}>
                    {eventType}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>

        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
    </Container>
  );
};

export default AwsRankingForm;
