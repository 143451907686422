import { useEffect, useState, useContext } from "react";
import { offerwallapi } from "../../config/axiosconfig";
import { Link, useParams } from "react-router-dom";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//     faHeart,
//     faEnvelope,
//     faCopy,
// } from "@fortawesome/free-regular-svg-icons";
import "./offerdetails.css";
// import PageHeader from "../../components/masterlayout/PageHeader";
// import Loader from "../../components/skeleton/Loader";
import { ClientContext } from "../../context/client/ClientContext";
import { useNavigate } from "react-router-dom";
// import ISaveMoreModal from "../../components/masterlayout/ISaveMoreModal";
// import { FavouritesContext } from "../../context/favourites/FavouritesContext";
// import { addFavourites, deleteFavourites } from "../../utils/favourites";
// import { setFavourites } from "../../utils/offers";
import { OffersContext } from "../../context/offers/OffersContext";
import OfferList from "../../components/offers/RecommendedOffers";
import { TenantContext } from "../../context/tenant/TenantContext";
import RecommendedOffers from "../../components/offers/RecommendedOffers";
import { FormatDateShortMonth } from "../../utils/dates";
import Skeleton from "../../components/skeletons/Skeleton";
import DOMPurify from "dompurify";
// import OfferList from "../../components/skeleton/OfferList";
// import FiveOfferSection from "../../components/sectionlayouts/FiveOfferSection";
// import "../../css/style.css";


export default function OfferDetail() {
    const { client } = useContext(ClientContext);
    const { offers, offersdispatch } = useContext(OffersContext);
    const { tenant } = useContext(TenantContext);

    // const { favourites, favouritesdispatch } = useContext(FavouritesContext);
    const navigate = useNavigate();
    let trackingUrl = "";

    const [offer, setOffer] = useState();
    const params = useParams();
    const offerid = params.offerid;
    const tenanturl = params.tenantpath;
    const [loader, setLoader] = useState(false);
    const [isGiveaway, setIsGiveaway] = useState(false);
    const [isFav, setIsFav] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const handleClose = () => setIsOpen(false);
    const handleShow = () => setIsOpen(true);
    const [copied, setCopied] = useState(false);
    const [isCouponCopied, setIsCouponCopied] = useState(false);

    const [isHow, setIsHow] = useState();

    // const getPathSegment = () => {
    //     const url = new URL(window.location.href);
    //     const pathname = url.pathname;
    //     const segments = pathname.split("/");
    //     const id = segments[segments.length - 1]; // Gets the last segment
    //     return id;
    // };

    // const allowedIds = [
    //     "2539",
    //     "2540",
    //     "2542",
    //     "2543",
    //     "2544",
    //     "2545",
    //     "2546",
    //     "2547",
    //     "2548",
    //     "7602",
    // ];
    // const id = getPathSegment();

    // if (!client) {
    //     if (!allowedIds.includes(id)) {
    //         // navigate("/login");
    //     }
    // } else {
    //     trackingUrl = offer?.trackingUrl + "&externalUserId=" + client.euid;
    // }

    const getOfferbyID = async () => {
        try {
            setLoader(true);

            const res = await offerwallapi.get("/api/offers/offerbyid", {
                params: {
                    tenantid: tenant.tenantid,
                    offerid
                }
            }
            );


            setOffer(res.data.offers[0]);
            // if (res.data.tags.length > 0) setIsHow(res.data.tags[0].trim() === "How");
            setLoader(false);

            setTimeout(() => {
                if (offer?.images.length > 1) document.getElementById("btnNextSlide").click();
            }, 3000)

        } catch (error) {
            console.log(error)
            setLoader(false);
        }
    };

    useEffect(() => {
        if (tenant) {
            setOffer(null);
            getOfferbyID();
        }

    }, [params, tenant]);

    useEffect(() => {

        if (!params.searchtext) {
            document.getElementById("txtGlobalSearch").value = "";
        }

    }, [])

    // useEffect(() => {
    //     if (favourites) {
    //         const favid = parseInt(params.favid ? params.favid : params.id);
    //         setIsFav(favourites.indexOf(favid) > -1);
    //     }
    // }, [favourites]);



    // useEffect(() => {
    //     const detailBread = [{ label: "Home", path: "/" }];
    //     detailBread.push({
    //         label: "Offers",
    //         path: `/`,
    //     });

    //     setBreadcrumbs(detailBread);
    // }, []);

    // const updateFavourites = async (e, offerid) => {
    //     e.preventDefault();
    //     try {
    //         const favs = [...favourites];
    //         favouritesdispatch({ type: "FAVOURITES_START" });

    //         if (favs.indexOf(offerid) === -1) {
    //             const res = await addFavourites(client.euid, offerid);
    //             favs.push(offerid);
    //         } else {
    //             const res = await deleteFavourites(client.euid, offerid);
    //             favs.splice(favs.indexOf(offerid), 1);
    //         }
    //         favouritesdispatch({ type: "FAVOURITES_SUCCESS", payload: favs });
    //         const offersOrganized = setFavourites(offers.all, favs);
    //         offersdispatch({ type: "OFFERS_SUCCESS", payload: offersOrganized });
    //     } catch (error) {
    //         console.log("FAV UPDATE ERROR", error);
    //         favouritesdispatch({ type: "FAVOURITES_FAIL", payload: error });
    //     }
    // };

    return (
        <>

            {/* <PageHeader title={offer?.title} breadcrumbs={breadcrumbs} /> */}
            <div className="container pt-30">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <i className="bi bi-house me-2"></i>
                            <Link to={`/${tenanturl}`} className="text-decoration-none text-black">Home</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to={`/${tenanturl}/offers`} className="text-decoration-none text-black">Offers</Link>
                        </li>
                        {
                            (offer) ?
                                <li className="breadcrumb-item">
                                    <Link to={`/${tenanturl}/merchant/${offer.merchant.name}`} className="text-decoration-none text-black">{offer?.merchant.name}</Link>
                                </li>
                                : <li className="breadcrumb-item active w-25" aria-current="page">
                                    <div className="placeholder-glow ms-3 mt-1">
                                        <div className="placeholder"></div>
                                    </div>
                                </li>
                        }
                        {
                            (offer) ?
                                <li className="breadcrumb-item active" aria-current="page" style={{ color: tenant?.tenantmasterconfig.theme.primary_text_color, width: "max-content" }}>{offer?.title}</li>
                                : <li className="breadcrumb-item active w-25" aria-current="page">
                                    <div className="placeholder-glow ms-3 mt-1">
                                        <div className="placeholder"></div>
                                    </div>
                                </li>
                        }
                    </ol>
                </nav>
                {
                    (offer) ?
                        <div className="row mt-5">
                            <div className="col-md-5">
                                <div
                                    id="divOD"
                                    className="carousel slide"
                                    data-bs-ride="carousel"
                                    data-ride="carousel"
                                >
                                    {/* Carousel indicators */}
                                    <div className="carousel-indicators od-carousel-indicators">
                                        {offer?.images.map((image, index) => (
                                            <button
                                                type="button"
                                                data-bs-target="#divOD"
                                                data-bs-slide-to={index}
                                                className={`${index === 0 ? "active" : ""} bg-dark`}
                                                key={index}
                                            ></button>
                                        ))}
                                    </div>

                                    {/* Carousel inner */}
                                    <div className="carousel-inner">
                                        {offer?.images.map((image, index) => (
                                            <div
                                                className={`carousel-item od-carousel-item ${index === 0 ? "active" : ""}`}
                                                key={index}
                                            >
                                                <img src={image} className="d-block w-100 rounded-4" alt="..." />
                                            </div>
                                        ))}
                                    </div>                        
                                </div>
                            </div>
                            <div className="col-md-7 mt-5 mt-md-0 ps-md-5">
                                <div className="od-card">
                                    <div className="card-body od-card-body">
                                        <div className="row">
                                            <div className="w-auto" style={{ width: "fit-content" }}>
                                                <img
                                                    src={offer?.merchant.image} // Replace with the path to your merchant logo
                                                    className="od-merchant-logo"
                                                    alt="Merchant Logo"
                                                />
                                            </div>
                                            <div className="col section_title">
                                                <h1 className="sub_title">
                                                    {offer?.merchant.name}
                                                </h1>
                                            </div>
                                        </div>

                                        <h3 className="od-offer-title mt-3">{offer?.title}</h3>
                                        {
                                            (tenant?.tenantmasterconfig.detail.show_desc) ?
                                                <p dangerouslySetInnerHTML={{ __html: offer?.description }}
                                                    className="od-offer-desscription"
                                                ></p>
                                                : ""
                                        }
                                                              

                                        <div className="offerOptionslist">
                                            <ul>
                                                {(offer?.minimumSpend) ? <li>Minimum spend of {offer?.currency} ${offer?.minimumSpend}</li> : ""}
                                                {(offer?.validTo) ? <li>Valid until {FormatDateShortMonth(offer?.validTo)}</li> : ""}
                                                {(offer?.merchant.website && tenant?.tenantmasterconfig.detail.show_merchant_url) ? 
                                                (
                                                    <a
                                                        href={offer.merchant.website}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className="btn-visit-website"
                                                    >
                                                    <i className="bi bi-shop"></i> Shop Now
                                                    </a>
                                                ) : "" 
                                                }
                                            </ul>
                                        </div>
                                        {/* <div className="mt-4 of-detail-font-size">
                                    <label className="fw-bold of-lbl-validDate">
                                        Offer Valid Period: &nbsp;
                                    </label>
                                    <label>
                                        {offer
                                            ? offer?.validTo
                                                ? ` Until ${new Date(offer?.validTo)
                                                    .toJSON()
                                                    .slice(0, 10)
                                                    .split("-")
                                                    .reverse()
                                                    .join("/")}`
                                                : ` ${new Date(offer?.validFrom)
                                                    .toJSON()
                                                    .slice(0, 10)
                                                    .split("-")
                                                    .reverse()
                                                    .join("/")} onwards`  -------------- #3BBD9C
                                            : ""}
                                    </label>
                                </div> */}
                                        <div className="onlineStore mb-3 ms-0" style={{ color: tenant?.tenantmasterconfig.theme.primary_text_color }}>
                                            {(offer?.salesChannel === "ALL") ? "Online & In-store" : (offer?.salesChannel === "E-COMMERCE") ? "Online" : "In-Store"}
                                        </div>
                                        <div className="mt-5 od-tc">
                                            <div className="row mt-5">
                                                <label className="fw-bold mt-3 mb-2" style={{ fontSize: "16px" }}>Terms and Conditions:</label>
                                            </div>
                                            <div className="row mt-3">
                                                <label
                                                    dangerouslySetInnerHTML={{
                                                        __html: DOMPurify.sanitize(offer?.termsAndConditions),
                                                    }}
                                                    className="ow-tnc"></label>
                                                <a href={DOMPurify.sanitize(offer?.tncURL)} target="_blank">{DOMPurify.sanitize(offer?.tncText)}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : <Skeleton type="offerdetail" />
                }

            </div>

            <div className="container mt-5">
                <div className="row section_title mt-5">
                    <h5 className="sub_title mt-5" style={{ fontSize: "20px" }}>Other Offers</h5>
                </div>
                <div className="mt-3 overflow-auto">
                    {
                        (offer) ?
                            <RecommendedOffers category={offer?.merchant.category.name} offerId={offer?.id} />
                            : <Skeleton type="offer" count="4" />
                    }
                    {/* {offers?.map((offer, index) => (
             index < 4 && <OfferItemSection key={offer.id} offer={offers} />
          ))} */}
                </div>
            </div>
            {/* <Loader loader={loader} /> */}
            {/* <ISaveMoreModal
                show={isOpen}
                handleClose={handleClose}
                handleShow={handleShow}
            /> */}
        </>
    );
}
