
import "./signup.css";

import { Button, Col, FloatingLabel, Form, Row, Stack } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useContext, useEffect, useRef, useState } from "react";
import { TenantContext } from "../../context/tenant/TenantContext";
import { ClientContext } from "../../context/client/ClientContext";
import { offerwallapi } from "../../config/axiosconfig";
import OneTimePassword from "../../components/auth/OneTImePassword";
import { LanguageContext } from "../../context/language/LanguageContext";
import Cookies from "universal-cookie";
import { CheckAuth } from "../../utils/auth";
import ReCAPTCHA from "react-google-recaptcha";
import { isAccordionItemSelected } from "react-bootstrap/esm/AccordionContext";

export default function SignIn() {

    const { tenant } = useContext(TenantContext);
    const { language } = useContext(LanguageContext);

    const txtclientemail = useRef();

    const captchaRef = useRef(null);
    const [validated, setValidated] = useState(false);
    const [message, setMessage] = useState("");
    const [isDisabled, setIsDisabled] = useState(true);
    const [captchaMessage, setCaptchaMessage] = useState("");
    const [success, setSuccess] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {

        if (CheckAuth()) navigate(`/${tenant.tenanturlname}`);
        // if (tenant) txtclientemail.current.focus();
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const ValidateInput = (event) => {

        const regexPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        const isValid = regexPattern.test(txtclientemail.current.value);
        const token = captchaRef.current.getValue();

        (isValid && token !== "") ? setIsDisabled(false) : setIsDisabled(true);

        // setIsDisabled((!isValid && token === ""));
    }
    // const ValidateCaptcha = (event) => {

    //     const regexPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    //     const isValid = regexPattern.test(txtclientemail.current.value);

    //     const token = captchaRef.current.getValue();
    //     alert(isValid);
    //     alert(token);
    //     (token !== "") ? setIsDisabled(false) : setIsDisabled(true);
    //     (isValid) ? setIsDisabled(false) : setIsDisabled(true);
    // }

    const clientSignIn = (event) => {
        const token = captchaRef.current.getValue();
        setMessage("");
        setCaptchaMessage("");
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        const regexPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        let isValid = regexPattern.test(txtclientemail.current.value);

        if (token === "") { setCaptchaMessage(language?.error_res.error_l7); }
        if (!isValid) { setMessage(language?.error_res.error_l1); }

        if (isValid && token !== "") {
            // setValidated(false);
            const signIn = async () => {
                // clientdispatch({ type: "CLIENT_START" });
                let client = {
                    // clientname: txtclientname.current.value,
                    clientemail: txtclientemail.current.value,
                    // clientpassword: txtclientpassword.current.value,
                    // clientname : txtclientconfirmpassword.current.value,
                    // clientmobilenumber: txtclientmobilenumber.current.value,
                    // clientvisacard: txtclientvisacard.current.value,
                    clienttenantid: tenant.tenantid,
                    captchatoken: token
                }

                try {
                    const res = await offerwallapi.post("/api/clients/clientlogin", client);

                    // if (res.data.hasOwnProperty("message")) {
                    if (res.data.code === "OWMS0002") {
                        setSuccess(true);
                    } else if(res.data.code === "OWMS0004") {
                        setMessage(language?.error_res.error_l14);
                    } else if (res.data.code === "OWER0003") {
                        setMessage(language?.error_res.error_l16);
                    }  else if (res.data.code === "OWER0002") {
                        setMessage(language?.error_res.error_l14);
                    } else if (res.data.code === "OWER0001") {
                        setMessage(language?.error_res.error_l17);
                    }
                    // clientdispatch({ type: "CLIENT_FAIL", payload: true });
                    // } else {
                    //     setSuccess(true);
                    //     // clientdispatch({ type: "CLIENT_SUCCESS", payload: res.data });
                    //     // navigate(`/${tenant.tenanturlname}/signin`);
                    // }
                    captchaRef.current.reset();

                } catch (err) {
                    // alert("ERROR");
                    // lblerrormessage.current.value = `Your email address is invalid. Please contact ${tenant.tenantname}`;
                    // clientdispatch({ type: "CLIENT_FAIL", payload: true });
                    setMessage(`${language?.error_res.error_l8} ${tenant.tenantname}`);
                    captchaRef.current.reset();
                }
            }
            signIn();

            // navigate("/" + tenant.tenanturlname);
        }
    };
    return <>
        {(tenant) ?
            <div>
                <Form noValidate validated={validated} onSubmit={clientSignIn}>
                    <Stack className="col-md-4 mx-auto mt-5 pt-5 text-center">
                        <div className="ms-4 me-4">
                            <Link to={`/${tenant.tenanturlname}`} className="text-dark" style={{ fontSize: "30px", float: "left" }}>
                                <i className="bi bi-chevron-left"></i>
                            </Link>
                            <h3>{language?.signin_labels.signin_l1}</h3>
                        </div>
                        <p className="small">{language?.signin_labels.signin_l2}</p>
                        <p className="small">{language?.signin_labels.signin_l3}&nbsp;
                            <Link to={`/${tenant.tenanturlname}/signup`} variant="primary" className="ow-primary">{language?.signin_labels.signin_l4}</Link></p>
                    </Stack>

                    <Stack className="col-md-4 mx-auto text-center" style={{ display: (!success) ? "" : "none" }}>

                        <Form.Group controlId="validationCustom03" className="ms-4 me-4">
                            <Row>
                                <Col sm="12" md="12">
                                    <FloatingLabel
                                        controlId="txtclientemail"
                                        label={language?.signin_labels.signin_l5}
                                        className="mb-3 mt-3 small">
                                        <Form.Control type="email" placeholder={language?.signin_labels.signin_l6} ref={txtclientemail}
                                            required
                                            onChange={ValidateInput}
                                        // pattern='/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/'
                                        />
                                        <Form.Control.Feedback type="invalid" className="text-right">
                                            {language?.error_res.error_l1}
                                        </Form.Control.Feedback>
                                    </FloatingLabel>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    {(message !== "") ? <Form.Label className="small text-danger">
                                        {message}
                                    </Form.Label> : ""}
                                </Col>
                            </Row>
                        </Form.Group>

                    </Stack>
                    <Stack className="col-md-4 mx-auto text-center">
                        {(success) ?
                            <Form.Text className="small m-3 ms-4 me-4 ow-bg-primary-light p-5 fs-5" >
                                {language?.signin_labels.signin_l7}
                            </Form.Text>
                            :
                            <div>
                                <div className="d-grid ms-4 me-4 ">
                                    <ReCAPTCHA ref={captchaRef} sitekey="6LcUouMgAAAAAOSIM89UzdNdAMFKT8BlJqgOXzKG" //{process.env.REACT_APP_RECAPTCHA_KEY}
                                        style={{ margin: "auto" }} onChange={ValidateInput}
                                    />
                                    {(captchaMessage !== "") ? <p className="small text-danger">{captchaMessage}</p> : ""}
                                </div>
                                <div className="d-grid mb-sm-3 mb-md-5 mt-3 ms-4 me-4">
                                    <Button type="submit" variant="primary" className="fw-bold p-3 ow-btn-primary" disabled={isDisabled} >{language?.signin_labels.signin_b1}</Button>
                                </div>
                            </div>
                        }
                        <p className="small">
                            {language?.signin_labels.signin_l8} &nbsp;
                            <Link to={`/${tenant.tenanturlname}`} variant="primary" className="ow-primary">{language?.signin_labels.signin_l9}</Link>
                        </p>

                    </Stack>
                </Form>
                {/* <OneTimePassword clientemail={txtclientemail.current.value} /> */}
            </div>
            : ""}
    </>
}

