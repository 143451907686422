const CategoriesReducer = (state, action) => {
    switch (action.type) {
        case "CATEGORIES_START":
            return {
                categories: null,
                categoriesIsFetching: true,
                categoriesError: false
            };
        case "CATEGORIES_SUCCESS":
            return {
                categories: action.payload,
                categoriesIsFetching: false,
                categoriesError: false
            };
        case "CATEGORIES_FAIL":
            return {
                categories: { categories: [] },
                categoriesIsFetching: false,
                categoriesError: action.payload
            };
        case "CATEGORIES_CLEAR":
            return {
                categories: { categories: [] },
                categoriesIsFetching: false,
                categoriesError: false
            };

        default: return state;

    }
}
export default CategoriesReducer;