
const monthShortNames = ["Jan", "Feb", "Mar", "Apr",
    "May", "Jun", "Jul", "Aug",
    "Sep", "Oct", "Nov", "Dec"];

export const FormatDateShortMonth = (date) => {


    const d = new Date(date);
    const day = d.getDate();
    const monthIndex = d.getMonth();
    const monthName = monthShortNames[monthIndex];

    const year = d.getFullYear();

    return `${day} ${monthName} ${year}`;
}

export const addDays = function(date, days) {
    var d = new Date(date);
    d.setDate(d.getDate() + days);
    return d;
}

export const minusDays = function(date, days) {
    var d = new Date(date);
    d.setDate(d.getDate() - days);
    return d;
}