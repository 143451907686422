import { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { TenantContext } from "../../context/tenant/TenantContext";
import DOMPurify from "dompurify";
import "./tenantterms.css";
import { Link, useLocation } from "react-router-dom";
import { LanguageContext } from "../../context/language/LanguageContext";


export default function TenantTerms() {
    const { tenant } = useContext(TenantContext);

    const url = useLocation().pathname.split("/")[1];
    const { language } = useContext(LanguageContext);

    // useEffect(() => {
    //     // alert(tenant)
    //     setTerms(tenant.tenanttermsandconditions);
    // }, [tenant])

    return <Container>
        <Row className="">
            <Col className="pt-5">
                {/* <Link to={`/${url}`} className="text-dark mb-4 d-inline-block" style={{ fontSize: "30px" }}>
                    <i className="bi bi-chevron-left"></i>
                </Link> */}
                {/* <h4>Terms and Conditions</h4> */}
                {
                    (tenant) ?
                        <div>
                            <div className="text-dark" dangerouslySetInnerHTML={
                                { __html: DOMPurify.sanitize(tenant?.tenanttermsandconditions) }}></div>
                            {/* <div>{tenant.tenanttermsandconditions}</div> */}
                        </div>
                        :
                        <div>{language?.static_labels.terms_not_available}</div>
                }
            </Col>
        </Row>
    </Container >
}